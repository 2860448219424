import React, { useState, useEffect } from 'react';
import { Offcanvas, Button, Image } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import './custom.css';
import ProfilePhoto from './Fish5.jpg';

const App = (props) => {
    const [isVisible, setIsVisible] = useState(false);
    const [option, setOption] = useState("MAIN");
    const [view, setView] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const profileView = <div className={"ProfileView" + (isMobile ? "Mobile" : "")}>
        <div className="ProfilePhoto">
            <Image src={ProfilePhoto} roundedCircle />
        </div>

        <div className="ProfileInfo">
            Nashelsky Reyes
        </div>

        <div className="ProfileMisc">Software Engineer</div>
        <div className="ProfileMisc">BS in Computer Science</div>
        <div className="ProfileMisc">West Palm Beach, FL</div>
         
        <div className="SocialButtons">
            <Icon.Linkedin size={30} />
            <Icon.Github size={30} />
            <Icon.Instagram size={30} />
        </div>
    </div>;

    const mainView = <div className={"MainView" + (isMobile ? "Mobile" : "")}>
        <div className="MainHello">Hello, World.</div>
        <div className="MainHeader">I'm <p className="MainName">Nashelsky</p>.</div>
        <div className="">I'm a software engineer and a human equivalent of a broken link.</div>
        <div className="">I have two dogs - Roscoe and Maple, and two fish - Salmon and Shark. My favorite fish is a sunfish.</div>
        <div className="">I like to watch documentaries with my dogs, and going to the dog park.</div>
    </div>;

    const workView = <div className={"WorkView" + (isMobile ? "Mobile" : "")}>
    </div>;

    const schoolView = <div className={"SchoolView" + (isMobile ? "Mobile" : "")}>
    </div>;

    const contactView = <div className={"ContactView" + (isMobile ? "Mobile" : "")}>
    </div>;

    if (view == null) {
        switch (option) {
            case "MAIN":
                setView(mainView);
                break;
            case "WORK":
                setView(workView);
                break;
            case "SCHOOL":
                setView(schoolView);
                break;
            case "CONTACT":
                setView(contactView);
                break;
        }
    }

    return (
        <div className="AppContainer">
            {profileView}
            {view}
        </div>
    );
}

export default App;